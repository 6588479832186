export default [
	{ name: 'calculator' },
	{ name: 'color' },
	{ name: 'converter' },
	{ name: 'counterparty' },
	{ name: 'currency_converter' },
	{ name: 'dict_fact' },
	{ name: 'discover_places' },
	{ name: 'ecom_research' },
	{ name: 'ecommerce_constr' },
	{ name: 'entity_card' },
	{ name: 'entity_search' },
	{ name: 'entity-reviews' },
	{ name: 'fact' },
	{ name: 'find_results_on' },
	{ name: 'flights' },
	{ name: 'images' },
	{ name: 'lyrics' },
	{ name: 'map' },
	{ name: 'maps' },
	{ name: 'math' },
	{ name: 'misspell' },
	{ name: 'music' },
	{ name: 'news_carousel' },
	{ name: 'panoramas' },
	{ name: 'poetry_lover' },
	{ name: 'post_index' },
	{ name: 'poster' },
	{ name: 'questions_answers' },
	{ name: 'recipes' },
	{ name: 'route' },
	{ name: 'special_event' },
	{ name: 'sport' },
	{ name: 'sports_app' },
	{ name: 'stocks' },
	{ name: 'time' },
	{ name: 'top_stories' },
	{ name: 'translate' },
	{ name: 'trash_info_warning' },
	{ name: 'unisearch-applications' },
	{ name: 'unisearch-education' },
	{ name: 'unisearch-medicine' },
	{ name: 'video' },
	{ name: 'videoshort' },
	{ name: 'weather' },
	{ name: 'weather_forecast' },
	{ name: 'no_icon' }
]
