<template>
	<v-data-table
		:loading="loading"
		:headers="headers"
		:items="filteredWizardGroupItems"
		class="elevation-1"
		:footer-props="{
			itemsPerPageAllText: 'Все',
			itemsPerPageText: 'Строк на стр.'
		}"
		:items-per-page="-1"
		loading-text="Загрузка данных"
	>
		<template v-slot:top>

			<v-toolbar flat>
				<v-toolbar-title>Список групп колдунщиков</v-toolbar-title>
				<v-divider vertical inset class="mx-6"/>
				<v-col cols="6">
					<v-text-field
						outlined
						hide-details
						clearable
						dense
						label="Поиск"
						v-model="searchItem"
					/>
				</v-col>
				<v-dialog
					v-model="dialogCreate"
					max-width="500px"
				>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							color="primary"
							dark
							class="white--text"
							v-bind="attrs"
							v-on="on"
						>
							<v-icon left dark>mdi-plus</v-icon>
							Добавить
						</v-btn>
					</template>
					<v-card>
						<v-card-title>Новая группа колдунщиков</v-card-title>
						<v-card-text>
							<v-form
								ref="form"
								v-model="valid"
								lazy-validation
							>
								<v-container>
									<v-row>
										<v-col
											cols="12"
											sm="12"
											md="12"
										>
											<v-switch
												v-model="active"
												:label="'Активен'"/>
										</v-col>
									</v-row>
									<v-row>
										<v-col
											cols="12"
											sm="12"
											md="12"
										>
											<v-text-field
												v-model="newWizardGroup.title"
												label="Имя ru"
												outlined
												hide-details="auto"
												:rules="[rules.required]"
											/>
										</v-col>
									</v-row>
									<v-row>
										<v-col
											cols="12"
											sm="12"
											md="12"
										>
											<v-combobox
												v-model="newWizardGroup.icon_name"
												label="Иконка"
												outlined
												:items="wizardIcons"
												item-text="name"
												:return-object="false"
												:rules="[rules.required]"
												hide-details="auto"
												:search-input.sync="search"
											>
												<template #item="{item }">
													<v-list-item-icon ><icon fill="grey" :name="item.name"/></v-list-item-icon >
													{{item.name}}
												</template>
												<template #selection="{ item }">
													<v-list-item-icon ><icon fill="grey" :name="item"/></v-list-item-icon >
													{{item}}
												</template>
												<template v-slot:no-data>
													<v-list-item>
														<v-list-item-content>
															<v-list-item-title>
																Совпадений не найдено. Нажмите <kbd>Enter</kbd>, чтобы добавить новое <br> местоположение подсказки
															</v-list-item-title>
														</v-list-item-content>
													</v-list-item>
												</template>
											</v-combobox>
										</v-col>
									</v-row>

								</v-container>
							</v-form>
						</v-card-text>
						<v-card-actions>
							<v-spacer/>
							<v-btn
								color="grey darken-1"
								text
								@click="resetForm"
							>
								Отменить
							</v-btn>
							<v-btn
								color="blue darken-1"
								text
								:disabled="!valid"
								@click="saveEntity"
							>
								Создать
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
				<v-spacer/>
				<v-dialog v-model="dialogDelete" max-width="500px">
					<v-card>
						<v-card-title>Точно удалить?</v-card-title>
						<v-card-actions>
							<v-spacer/>
							<v-btn color="grey darken-1" text @click="resetForm">Отмена</v-btn>
							<v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
							<v-spacer/>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</v-toolbar>
		</template>

		<template v-slot:item.active="props">
			<v-switch
				:input-value="props.item.active ? true : false"
				@change="(val)=>{
					props.item.active = (val ? 1 : 0)
					putWizardGroup({input:props.item})
				}"
				/>
		</template>
		<template v-slot:item.wizards="props">
			<v-chip-group
				column
				v-if="wizardsListMap[props.item.id]"
			>
				<v-chip
					v-for="tag in wizardsListMap[props.item.id]"
					:key="tag.seo_api_name"
				>
					{{ tag.seo_api_name }}
				</v-chip>

			</v-chip-group>
			<template v-else>
				Пустая группа
			</template>
		</template>
		<template v-slot:item.title="props">
			<v-label-editable
				v-model="props.item.title"
				:rules="[rules.required]"
				@input="putWizardGroup({input:props.item, isRefresh:true})"
			/>
		</template>
		<template v-slot:item.title_en="props">
			<v-label-editable
				v-model="props.item.title_en"
				:rules="[rules.required]"
				@input="putGroupTranslate(props.item)"
			/>
		</template>
		<template v-slot:item.iconName="props">
			<v-label-editable
				v-model="props.item.icon_name"
				:options="wizardIcons"
				:rules="[rules.required]"
				@input="putWizardGroup({input:props.item})"
			>
				<template #item="{ index, item }">
					<v-list-item-icon ><icon fill="grey" :name="item.name"/></v-list-item-icon >
					{{item.name}}
				</template>
				<template #selection="{ item }">
					{{item}}<icon fill="grey" :name="item"/>
				</template>
				<template #fake-value="{ item }">
					{{item}}<icon class="ml-3" fill="grey" :name="item"/>
				</template>
			</v-label-editable>
		</template>
		<template v-slot:item.actions="{ item }">
			<v-btn
				icon
				small
				@click="deleteItem(item)"
			>
				<v-icon small>mdi-delete</v-icon>
			</v-btn>
		</template>
		<template v-slot:no-data>
			<v-btn
				color="primary"
				@click="initialize"
			>
				Сбросить
			</v-btn>
		</template>
	</v-data-table>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import labelEditable from '@/components/labelEditable'
import wizardConst from '@/services/dashboard/tools/i18n/pages/components/wizardConst'

export default {
	metaInfo () {
		return {
			title: this.title
		}
	},
	components: {
		'v-label-editable': labelEditable
	},
	data: function () {
		return {
			title: 'Переводы сущностей справочников',
			loading: false,
			dialogDelete: false,
			dialogCreate: false,
			valid: true,
			newWizardGroup: {
				title: '',
				icon_name: '',
				active: 0
			},
			rules: {
				required: value => !!value || 'Поле обязательно'
			},
			wizardIcons: wizardConst,
			search: undefined,
			headers: [
				{
					text: 'Активен',
					align: 'start',
					sortable: false,
					value: 'active'
				},
				{
					text: 'Название группы Ру',
					sortable: true,
					value: 'title'
				},
				{
					text: 'Исходные колдунщики',
					sortable: false,
					width: '450px',
					value: 'wizards'
				},
				{
					text: 'Название группы En',
					sortable: true,
					value: 'title_en'
				},
				{
					text: 'Иконка',
					sortable: true,
					value: 'iconName'
				},
				{
					text: 'Действия',
					value: 'actions',
					sortable: false
				}
			],
			editedIndex: -1,
			editedItem: {
				translate: ''
			},

			defaultItem: {
				translate: ''
			},
			searchItem: ''
		}
	},

	computed: {
		...mapGetters({
			wizardsGroupList: 'i18n/wizardsGroupList',
			wizardsList: 'i18n/wizardsList'
		}),
		wizardsListMap () {
			return this.wizardsList.items.reduce((acc, it) => ({
				...acc,
				[it.group_id]: acc[it.group_id] ? [...acc[it.group_id], it] : [it]
			}), {})
		},
		active: {
			get () {
				return this.newWizardGroup.active ? 1 : 0
			},
			set (val) {
				this.newWizardGroup.active = val ? 1 : 0
			}
		},
		filteredWizardGroupItems () {
			let res = this.wizardsGroupList.items
			if (this.searchItem) { res = res.filter(it => it.title.toLowerCase().includes(this.searchItem.trim())) }
			return res
		}
	},
	mounted () {
		if (!this.wizardsGroupList.items.length || !this.wizardsList.items.length) {
			this.initialize()
		}
	},

	methods: {
		...mapActions({
			getWizardsGroupList: 'i18n/getWizardsGroupList',
			getWizardsList: 'i18n/getWizardsList',
			putWizardGroup: 'i18n/putWizardGroup',
			postWizardGroup: 'i18n/postWizardGroup',
			putTranslatesList: 'i18n/putTranslatesList',
			postTranslatesList: 'i18n/postTranslatesList',
			deleteWizardGroup: 'i18n/deleteWizardGroup'
		}),
		resetForm () {
			this.newWizardGroup = {
				title: '',
				icon_name: '',
				active: 1
			}
			this.dialogCreate = false
			this.dialogDelete = false
		},
		async saveEntity () {
			await this.$refs.form.validate()
			if (this.valid) {
				const res = await this.postWizardGroup(this.newWizardGroup)
				if (res) {
					this.initialize()
					this.resetForm()
				}
			}
		},
		async deleteItemConfirm () {
			const res = await this.deleteWizardGroup(this.editedItem)
			if (res) {
				this.initialize()
				this.resetForm()
			}
		},
		async initialize () {
			this.loading = true
			try {
				await this.getWizardsGroupList()
				await this.getWizardsList()
			} catch (error) {
				this.$notify({
					type: 'error',
					title: error
				})
			}

			this.loading = false
		},
		putGroupTranslate (item) {
			const action = item.translates_id ? 'putTranslatesList' : 'postTranslatesList'
			try {
				this[action](
					{
						id: item.translates_id,
						translate: item.title_en,
						entity_id: item.id,
						entity_type_id: 13,
						lang_id: 2
					}
				).then(
					() => {
						this.$notify({ type: 'info', title: item.translates_id ? 'Изменения сохранены' : 'Перевод создан' })
						this.initialize()
					}, (error) => {
						this.$notify({
							type: 'error',
							title: error
						})
					}
				)
			} catch (error) {
				this.$notify({
					type: 'error',
					title: error
				})
			}
			this.loading = false
		},
		deleteItem (item) {
			this.editedItem = { ...item }
			this.dialogDelete = true
		}

	}
}
</script>
